<template>
  <div class="container">
    <div class="row justify-content-center" v-if="loaded">
      <div class="col-lg-5 col-md-7">
        <div class="card bg-secondary card-body shadow">
          <div class="row justify-content-center mb-3 mt-2">
            <img
              src="/img/brand/logo.png"
              style="height: 70px"
              alt="..."
              class="c-pointer"
              @click="$r.changeRoute('home')"
            />
          </div>

          <div v-if="!activationToken" class="text-center text-muted mb-4">
            <small>{{ $t.translate("LBL_FILL_IN_ACC_INFO") }}</small>
          </div>

          <template v-if="!activationToken">
            <base-input
              v-model="formData.FirstName"
              :placeholder="$t.translate('FRM_FIRSTNAME')"
              addon-left-icon="fas fa-user"
              :formClasses="{
                'input-group-alternative': true,
                'mb-3': true,
                'inp-invalid': $h.isBlank(formData.FirstName),
              }"
            >
            </base-input>
            <base-input
              v-model="formData.LastName"
              :placeholder="$t.translate('FRM_LASTNAME')"
              addon-left-icon="fas fa-user"
              :formClasses="{
                'input-group-alternative': true,
                'mb-3': true,
                'inp-invalid': $h.isBlank(formData.LastName),
              }"
            >
            </base-input>
            <base-input
              v-model="formData.Email"
              :disabled="formData.Locked"
              :placeholder="$t.translate('FRM_EMAIL')"
              addon-left-icon="fas fa-envelope"
              :formClasses="{
                'input-group-alternative': true,
                'mb-3': true,
                'inp-invalid':
                  $h.isBlank(formData.Email) || !$h.validateEmail(formData.Email),
              }"
            >
            </base-input>
            <base-input
              v-model="formData.NewPassword"
              :placeholder="$t.translate('FRM_NEW_PASSWORD')"
              type="password"
              :reveal="true"
              addon-left-icon="ni ni-lock-circle-open"
              :formClasses="{
                'input-group-alternative': true,
                'mb-3': true,
                'inp-invalid': $h.isBlank(formData.NewPassword),
              }"
            >
            </base-input>

            <el-alert
              :closable="false"
              :title="$t.translate('MSG_ERR_PASSWORD_INVALID')"
              type="warning"
              class="custom-alert mb-3"
            />

            <base-input
              v-model="formData.ConfirmPassword"
              :placeholder="$t.translate('FRM_CONFIRM_PASSWORD')"
              type="password"
              :reveal="true"
              addon-left-icon="ni ni-lock-circle-open"
              :formClasses="{
                'input-group-alternative': true,
                'mb-3': true,
                'inp-invalid': $h.isBlank(formData.ConfirmPassword),
              }"
            >
            </base-input>

            <base-input
              v-model="formData.BusinessStaffEmail"
              :disabled="formData.Locked"
              :placeholder="$t.translate('FRM_HKQAA_BUSIN_STAFF_EMAIL')"
              addon-left-icon="fas fa-envelope"
              :formClasses="{
                'input-group-alternative': true,
                'mb-3': true,
                'inp-invalid':
                  $h.isBlank(formData.BusinessStaffEmail) ||
                  !$h.validateEmail(formData.BusinessStaffEmail),
              }"
            >
            </base-input>

            <div class="el-alert el-alert--warning is-light custom-alert mb-3">
              <div class="el-alert__content">
                <span
                  class="el-alert__title"
                  v-html="
                    $h.replaceAllEmail($t.translate('MSG_REGIS_REMINDER_ABOUT_STAFF'))
                  "
                >
                </span>
              </div>
            </div>

            <el-input
              addon-left-icon="fas fa-building"
              :maxlength="$h.getCharLimit().OrgName"
              show-word-limit
              v-model="formData.Company"
              :class="{
                'input-group-alternative': true,
                'mb-3': true,
                'inp-invalid': $h.isBlank(formData.Company),
              }"
              :placeholder="$t.translate('FRM_REGIS_ORG_NAME')"
            />

            <el-input
              addon-left-icon="far fa-building"
              :maxlength="$h.getCharLimit().BranchName - (formData.Company.length + 1)"
              show-word-limit
              v-model="formData.Branch"
              class="input-group-alternative mb-3"
              :placeholder="$t.translate('FRM_REGIS_BRANCH_OPTIONAL')"
            />

            <div class="row">
              <div class="col-12">
                <el-checkbox v-model="formData.isAgreed">
                  <span class="text-muted">
                    {{ $t.translate("LBL_I_AGREE") }}
                    <a href="javascript:void(0)" @click="showModal = true">{{
                      $t.translate("BTN_DISCLAIMER")
                    }}</a>
                  </span>
                </el-checkbox>
              </div>

              <div class="col-12">
                <el-checkbox v-model="formData.isAgreedTC">
                  <span class="text-muted">
                    {{ $t.translate("LBL_I_ACCEPT") }}
                    <a href="javascript:void(0)" @click="showModalTC = true">{{
                      $t.translate("BTN_TERMS_CONDI")
                    }}</a>
                  </span>
                </el-checkbox>
              </div>
            </div>

            <div class="text-center">
              <el-button type="primary" class="mt-3 mb-3" @click="sendLink()">{{
                $t.translate("BTN_REGISTER_ACC")
              }}</el-button>
            </div>
          </template>

          <template v-else>
            <div class="text-center my-1">
              <small>{{ $t.translate("LBL_ACC_ACTIVATED_LOGIN_NOW") }}</small>

              <div></div>

              <el-button type="primary" class="mt-4" @click="$r.changeRoute('/login')">{{
                $t.translate("BTN_GO_LOGIN")
              }}</el-button>
            </div>
          </template>
        </div>
        <!-- 
        <div v-if="!activationToken" class="my-3">
          <a
            class="h5 text-muted"
            href="javascript:void(0)"
            @click="$r.changeRoute('/login')"
            ><i class="fas fa-chevron-left mr-2"></i
            >{{ $t.translate("BTN_BACK_TO_LOGIN") }}</a
          >
        </div> -->
      </div>
    </div>

    <modal v-model:show="showModal">
      <template #header>
        <h2 id="exampleModalLabel" class="modal-title text-dark">
          {{ $t.translate("TTL_DISCLAIMER") }}
        </h2>
      </template>
      <div class="disc-content mt--4">
        <Disclaimer :key="`currentlang-${$t.getLanguage()}`" />

        <el-checkbox v-model="formData.isAgreed" style="margin-top: 10px">
          {{ $t.translate("MSG_ACCEPT_DISC_ABOVE") }}
        </el-checkbox>
      </div>
      <template #footer>
        <el-button type="primary" @click="showModal = false">{{
          $t.translate("BTN_CLOSE")
        }}</el-button>
      </template>
    </modal>

    <modal v-model:show="showModalTC">
      <template #header>
        <h2 id="exampleModalLabel" class="modal-title text-dark">
          {{ $t.translate("TTL_TERMS_AND_CONDI") }}
        </h2>
      </template>
      <div class="disc-content mt--4">
        <TC :key="`currentlang-${$t.getLanguage()}`" />

        <el-checkbox v-model="formData.isAgreedTC" style="margin-top: 10px">
          {{ $t.translate("MSG_ACCEPT_TC_ABOVE") }}
        </el-checkbox>
      </div>
      <template #footer>
        <el-button type="primary" @click="showModalTC = false">{{
          $t.translate("BTN_CLOSE")
        }}</el-button>
      </template>
    </modal>
  </div>
</template>
<script>
import { onMounted, ref, reactive } from "vue";
import { useRoute } from "vue-router";
import { post, get, isValidResponse } from "@/utils/axios";
import { helpers } from "@/utils/helpers";
import { router } from "@/utils/router";
import { store } from "@/store";

import TC from "@/components/base/TC";
import Disclaimer from "@/components/base/Disclaimer";

export default {
  components: {
    Disclaimer,
    TC,
  },
  setup() {
    const route = useRoute();
    let loaded = ref(false);
    let activationToken = ref(null);
    let formData = reactive({
      FirstName: "",
      LastName: "",
      Email: "",
      NewPassword: "",
      ConfirmPassword: "",
      BusinessStaffEmail: "",
      Company: "",
      Branch: "",
      isAgreed: false,
      isAgreedTC: false,
    });

    let showModal = ref(false);
    let showModalTC = ref(false);

    onMounted(async () => {
      if (route?.query?.token) {
        let res = await post("/auth/activate", { Token: route.query.token });
        if (!isValidResponse(res)) {
          //redirect to /register
          router.replaceRoute("/login");
        } else {
          activationToken.value = route.query.token;
        }
      }

      if (route?.query?.invite) {
        let res = await post("/auth/exchange", { Token: route.query.invite });
        if (!isValidResponse(res)) {
          //redirect to /register
          router.replaceRoute("/login");
        } else {
          formData.Locked = route.query.invite;
          formData.Email = res.Token.Email;
          formData.BusinessStaffEmail = res.Token.StaffEmail;
          formData.Company = res.Token.Company;
          formData.Branch = res.Token.Branch;
        }
      }

      loaded.value = true;
    });

    const sendLink = async () => {
      try {
        if (!formData.isAgreed)
          throw {
            Code: "ERR_AGREE_TO_DISCLAIMER",
            Message: "Please agree to disclaimer to continue",
          };

        if (!formData.isAgreedTC)
          throw {
            Code: "ERR_AGREE_TO_DISCLAIMER",
            Message: "Please agree to terms and conditions",
          };

        let {
          TermsVersion,
          TermsVersionDate,
          PrivacyVersion,
          PrivacyVersionDate,
        } = await helpers.getTCVersion();
        let res = await post("/auth/register", {
          ...formData,
          TermsVersion,
          TermsVersionDate,
          PrivacyVersion,
          PrivacyVersionDate,
        });
        if (!isValidResponse(res)) return;
        //send message
        formData.FirstName = "";
        formData.LastName = "";

        formData.Email = "";
        formData.NewPassword = "";
        formData.ConfirmPassword = "";
        formData.BusinessStaffEmail = "";
        formData.Company = "";
        formData.Branch = ""; //reset to empty

        // if (formData.Locked) {
        //   await store.dispatch("page/setData", { isSuccessActivation: true });
        // } else {
        await store.dispatch("page/setData", { isSuccessReg: true });
        // }
        router.replaceRoute("/login");
      } catch (err) {
        helpers.catchError(err);
      }
    };

    return { loaded, activationToken, formData, showModal, showModalTC, sendLink };
  },
};
</script>
<style type="text/css">
.custom-alert {
  line-height: 16px !important;
  padding: 6px 5px !important;
}
.custom-alert .el-alert__title {
  line-height: 14px;
  font-size: 0.68rem !important;
}
.input-group.inp-invalid {
  border: 1px solid #fb6340;
}
.modal-content strong {
  font-weight: bold;
}

.el-checkbox a {
  text-decoration: underline;
}
</style>
