<template>
  <section v-html="Data.content"></section>
</template>

<script>
import { onMounted, ref, reactive } from "vue";
import { post, get, isValidResponse } from "@/utils/axios";

export default {
  setup() {
    let Data = ref({});

    onMounted(async () => {
      Data.value = await get("/cms/get/tc-for-registration.json");
    });

    return { Data };
  },
};
</script>

<style scoped>

</style>